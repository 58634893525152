import React from 'react';

const Class = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="icon-class"
    viewBox="0 0 500 400"
  >
    <title id="icon-class">Class</title>
    <path d="M200 225c0 27.617 22.391 50 50 50 27.617 0 50-22.383 50-50s-22.383-50-50-50c-27.609 0-50 22.383-50 50zm175 0c0 27.617 22.391 50 50 50 27.617 0 50-22.383 50-50s-22.383-50-50-50c-27.609 0-50 22.383-50 50zM75 275c27.617 0 50-22.383 50-50s-22.383-50-50-50c-27.609 0-50 22.383-50 50s22.391 50 50 50zm375 25h-50c-27.609 0-50 22.383-50 50v25c0 13.805 11.195 25 25 25h100c13.805 0 25-11.195 25-25v-25c0-27.617-22.391-50-50-50zm-175 0h-50c-27.609 0-50 22.383-50 50v25c0 13.805 11.195 25 25 25h100c13.805 0 25-11.195 25-25v-25c0-27.617-22.391-50-50-50zm-175 0H50c-27.609 0-50 22.383-50 50v25c0 13.805 11.195 25 25 25h100c13.805 0 25-11.195 25-25v-25c0-27.617-22.391-50-50-50zM75 50h350v100c19.281 0 36.703 7.516 50 19.508V38.742C475 17.383 458.18 0 437.5 0h-375C41.82 0 25 17.383 25 38.742v130.766C38.297 157.516 55.727 150 75 150V50z" />
  </svg>
);

export default Class;
