import React from 'react';

const Link = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Chain link icon"
    role="img"
    viewBox="0 -0.001 500 500.002"
  >
    <title>Chain link</title>
    <desc>icon</desc>
    <path d="M318.957 181.046c58.347 58.407 57.546 152.049.352 209.561a10.76 10.76 0 0 1-.352.361l-65.625 65.625c-57.881 57.881-152.05 57.873-209.922 0-57.881-57.871-57.881-152.051 0-209.922l36.236-36.236c9.609-9.609 26.158-3.223 26.654 10.357.633 17.307 3.736 34.694 9.463 51.485 1.939 5.686.554 11.975-3.694 16.223l-12.78 12.78c-27.369 27.369-28.228 71.934-1.128 99.57 27.367 27.909 72.35 28.075 99.927.498l65.625-65.615c27.53-27.53 27.415-72.028 0-99.443-3.614-3.607-7.255-6.41-10.099-8.368a15.66 15.66 0 0 1-6.784-12.311c-.387-10.319 3.27-20.953 11.424-29.107l20.561-20.562c5.392-5.392 13.85-6.054 20.102-1.69a148.901 148.901 0 0 1 20.04 16.794zM456.589 43.407c-57.872-57.873-152.041-57.881-209.922 0l-65.625 65.625c-.117.117-.244.244-.352.361-57.193 57.512-57.995 151.153.352 209.561a148.832 148.832 0 0 0 20.04 16.793c6.252 4.363 14.711 3.7 20.102-1.69l20.561-20.562c8.154-8.154 11.811-18.788 11.424-29.107a15.664 15.664 0 0 0-6.784-12.311c-2.844-1.958-6.484-4.761-10.099-8.368-27.415-27.415-27.53-71.913 0-99.443L301.91 98.65c27.577-27.577 72.559-27.411 99.927.498 27.1 27.637 26.242 72.201-1.128 99.57l-12.78 12.78c-4.248 4.248-5.634 10.537-3.694 16.223 5.727 16.791 8.83 34.179 9.463 51.485.497 13.58 17.045 19.967 26.654 10.357l36.236-36.236c57.882-57.869 57.882-152.049.001-209.92z" />
  </svg>
);

export default Link;
