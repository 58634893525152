import React from 'react';

import Wrench from '../../assets/icons/wrench';

const Services = () => (
  <section
    className="facilities__section facilities__services"
    id="services"
  >
    <div className="facilities__section-inner">
      <h2>
        <Wrench />
        Services
      </h2>
      <p>
        Our service offers an initial consultation to determine the optimal and most cost-effective
        strategy for your project. We specialize in sample preparation, whether it&#39;s single cell or
        single nuclei isolation utilizing 10X Genomics or spatial RNA isolation from tissue using GeoMx.
        We also provide library preparation for both DNA and RNA sequencing, ensuring nucleic acid
        integrity through checks, DNA fragment library sizing, and library QC. Our expertise extends
        to short- and long-read sequencing, with comprehensive data review and consultation on
        subsequent steps. Additionally, we offer bioinformatics support to analyze and interpret
        your sequencing data effectively.
      </p>
    </div>
  </section>
);

export default Services;
