import React from 'react';

const Server = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Machine icon"
    role="img"
    viewBox="0 0 500 437.5"
  >
    <title>Machine</title>
    <desc>icon</desc>
    <path d="M468.75 125H31.25C13.991 125 0 111.009 0 93.75v-62.5C0 13.991 13.991 0 31.25 0h437.5C486.009 0 500 13.991 500 31.25v62.5c0 17.259-13.991 31.25-31.25 31.25zm-46.875-85.938c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438zm-62.5 0c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438zM468.75 281.25H31.25C13.991 281.25 0 267.259 0 250v-62.5c0-17.259 13.991-31.25 31.25-31.25h437.5c17.259 0 31.25 13.991 31.25 31.25V250c0 17.259-13.991 31.25-31.25 31.25zm-46.875-85.938c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438zm-62.5 0c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438zM468.75 437.5H31.25C13.991 437.5 0 423.509 0 406.25v-62.5c0-17.259 13.991-31.25 31.25-31.25h437.5c17.259 0 31.25 13.991 31.25 31.25v62.5c0 17.259-13.991 31.25-31.25 31.25zm-46.875-85.938c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438zm-62.5 0c-12.944 0-23.438 10.493-23.438 23.438s10.493 23.438 23.438 23.438 23.438-10.493 23.438-23.438-10.494-23.438-23.438-23.438z" />
  </svg>
);

export default Server;
