import React from 'react';

const File = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Document icon"
    role="img"
    viewBox="0 0 375 500"
  >
    <title>Document</title>
    <desc>icon</desc>
    <path d="M218.75 132.812V0H23.438A23.381 23.381 0 0 0 0 23.438v453.125A23.381 23.381 0 0 0 23.438 500h328.125A23.381 23.381 0 0 0 375 476.562V156.25H242.188c-12.891 0-23.438-10.547-23.438-23.438zm62.5 230.469c0 6.445-5.273 11.719-11.719 11.719H105.469c-6.445 0-11.719-5.273-11.719-11.719v-7.812c0-6.445 5.273-11.719 11.719-11.719h164.062c6.445 0 11.719 5.273 11.719 11.719v7.812zm0-62.5c0 6.445-5.273 11.719-11.719 11.719H105.469c-6.445 0-11.719-5.273-11.719-11.719v-7.812c0-6.445 5.273-11.719 11.719-11.719h164.062c6.445 0 11.719 5.273 11.719 11.719v7.812zm0-70.312v7.812c0 6.445-5.273 11.719-11.719 11.719H105.469c-6.445 0-11.719-5.273-11.719-11.719v-7.812c0-6.445 5.273-11.719 11.719-11.719h164.062c6.446 0 11.719 5.273 11.719 11.719zM375 119.043V125H250V0h5.957c6.25 0 12.207 2.441 16.602 6.836l95.605 95.703A23.362 23.362 0 0 1 375 119.043z" />
  </svg>
);

export default File;
