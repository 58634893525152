import React from 'react';

const Hands = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Hands holding money icon"
    role="img"
    viewBox="0 0 500 400"
  >
    <title>Hands holding money</title>
    <desc>icon</desc>
    <path d="M307.266 124.531C305 106.562 291.094 92.266 273.75 87.266l-39.141-11.172c-2.812-.781-4.766-3.438-4.766-6.328 0-3.594 2.969-6.562 6.562-6.562h25.625c2.812 0 5.547.625 8.047 1.719 3.75 1.719 8.125 1.328 11.016-1.562l13.672-13.672c4.141-4.141 3.672-11.172-1.172-14.375-7.422-4.922-15.938-7.891-24.844-8.984V12.5c0-6.875-5.625-12.5-12.5-12.5h-12.5c-6.875 0-12.5 5.625-12.5 12.5v13.75c-23.672 2.812-41.719 24.219-38.516 49.219 2.266 17.969 16.172 32.266 33.516 37.266l39.141 11.172c2.812.781 4.766 3.438 4.766 6.328 0 3.594-2.969 6.562-6.562 6.562h-25.625c-2.812 0-5.547-.625-8.047-1.719-3.75-1.719-8.125-1.328-11.016 1.562l-13.672 13.672c-4.141 4.141-3.672 11.172 1.172 14.375 7.422 4.922 15.938 7.891 24.844 8.984V187.5c0 6.875 5.625 12.5 12.5 12.5h12.5c6.875 0 12.5-5.625 12.5-12.5v-13.75c23.672-2.812 41.719-24.141 38.516-49.219zM160 180c-8.281-11.016-23.984-13.281-35-5s-13.281 23.984-5 35l29.766 39.688c3.75 5 3.203 11.953-1.172 16.328l-10 10c-5.234 5.234-13.75 4.844-18.438-.859L50 190.938V75c0-13.828-11.172-25-25-25S0 61.172 0 75v170.625a37.544 37.544 0 0 0 8.203 23.438l81.328 104.922c3.906 5.078 6.562 10.859 8.125 16.953 1.406 5.391 6.328 9.062 11.953 9.062H212.5c6.875 0 12.5-5.625 12.5-12.5V300c0-21.641-7.031-42.656-20-60l-45-60zM475 50c-13.828 0-25 11.172-25 25v115.938l-70.156 84.219c-4.688 5.625-13.281 6.016-18.438.859l-10-10c-4.375-4.375-4.922-11.328-1.172-16.328L380 210c8.281-11.016 6.016-26.719-5-35s-26.719-6.016-35 5l-45 60c-12.969 17.344-20 38.359-20 60v87.5c0 6.875 5.625 12.5 12.5 12.5h102.891c5.547 0 10.547-3.672 11.953-9.062 1.562-6.094 4.219-11.875 8.125-16.953l81.328-104.922A37.544 37.544 0 0 0 500 245.625V75c0-13.828-11.172-25-25-25z" />
  </svg>
);

export default Hands;
