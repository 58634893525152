import React from 'react';

const wrench = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Wrench icon"
    role="img"
    viewBox="0 0 500 500"
  >
    <title>Wrench</title>
    <desc>icon</desc>
    <path d="M495.832 106.542c-2.188-8.818-13.223-11.807-19.648-5.381l-72.617 72.617-66.289-11.045-11.045-66.289 72.617-72.617c6.465-6.465 3.35-17.48-5.527-19.688-46.27-11.465-97.217.889-133.379 37.041-38.71 38.711-49.365 94.824-33.251 143.75L18.303 393.319c-24.404 24.404-24.404 63.975 0 88.379s63.975 24.404 88.379 0l208.212-208.213c48.945 16.318 104.951 5.547 143.916-33.418 36.202-36.201 48.536-87.227 37.022-133.525zM62.502 460.937c-12.939 0-23.438-10.498-23.438-23.438 0-12.949 10.498-23.438 23.438-23.438S85.94 424.55 85.94 437.499c0 12.939-10.498 23.438-23.438 23.438z" />
  </svg>
);

export default wrench;
