import React from 'react';

const Envelope = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Envelope icon"
    role="img"
    viewBox="0 0 500 375"
  >
    <title>Envelope</title>
    <desc>icon</desc>
    <path d="M490.527 123.828c3.809-3.027 9.473-.195 9.473 4.59v199.707C500 354.004 479.004 375 453.125 375H46.875C20.996 375 0 354.004 0 328.125V128.516c0-4.883 5.566-7.617 9.473-4.59 21.875 16.992 50.879 38.574 150.488 110.938 20.605 15.039 55.371 46.68 90.039 46.484 34.863.293 70.312-32.031 90.137-46.484 99.609-72.364 128.515-94.044 150.39-111.036zM250 250c22.656.391 55.273-28.516 71.68-40.43 129.59-94.043 139.453-102.246 169.336-125.684C496.68 79.492 500 72.656 500 65.43V46.875C500 20.996 479.004 0 453.125 0H46.875C20.996 0 0 20.996 0 46.875V65.43c0 7.227 3.32 13.965 8.984 18.457 29.883 23.34 39.746 31.641 169.336 125.684 16.407 11.913 49.024 40.82 71.68 40.429z" />
  </svg>
);

export default Envelope;
