import React from 'react';

const Flask = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Flask icon"
    role="img"
    viewBox="0 0 437.496 500"
  >
    <title>Flask</title>
    <desc>icon</desc>
    <path d="M426.944 394.043L312.491 209.961V62.5h7.812a23.382 23.382 0 0 0 23.438-23.438V23.438A23.381 23.381 0 0 0 320.304 0H117.178a23.381 23.381 0 0 0-23.437 23.438v15.625a23.382 23.382 0 0 0 23.438 23.438h7.812v147.461L10.538 394.043C-18.076 440.039 14.932 500 69.229 500h299.024c54.394 0 87.305-60.059 58.691-105.957zM134.659 312.5l47.07-75.781c3.613-5.078 5.664-11.328 5.664-17.969V62.5h62.5v156.25c0 6.738 2.149 12.891 5.665 17.969l47.07 75.781H134.659z" />
  </svg>
);

export default Flask;
