import React from 'react';

const NewsPaper = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Newspaper icon"
    role="img"
    viewBox="0 0 500 333.334"
  >
    <title>Newspaper</title>
    <desc>icon</desc>
    <path d="M479.167 0H76.389C64.883 0 55.556 9.327 55.556 20.833v6.945H20.833C9.327 27.778 0 37.105 0 48.611v236.112c0 26.846 21.764 48.611 48.611 48.611h409.722c23.013 0 41.667-18.656 41.667-41.668V20.833C500 9.327 490.673 0 479.167 0zM48.611 291.666a6.945 6.945 0 0 1-6.945-6.943V69.444h13.889v215.278a6.943 6.943 0 0 1-6.944 6.944zm204.862-13.889H121.528c-5.752 0-10.417-4.664-10.417-10.416v-6.945c0-5.752 4.664-10.416 10.417-10.416h131.945c5.752 0 10.416 4.664 10.416 10.416v6.945c0 5.752-4.664 10.416-10.416 10.416zm180.554 0H302.084c-5.754 0-10.418-4.664-10.418-10.416v-6.945c0-5.752 4.664-10.416 10.418-10.416h131.943c5.753 0 10.417 4.664 10.417 10.416v6.945c0 5.752-4.664 10.416-10.417 10.416zm-180.554-83.333H121.528c-5.752 0-10.417-4.664-10.417-10.416v-6.945c0-5.752 4.664-10.416 10.417-10.416h131.945c5.752 0 10.416 4.664 10.416 10.416v6.945c0 5.752-4.664 10.416-10.416 10.416zm180.554 0H302.084c-5.754 0-10.418-4.664-10.418-10.416v-6.945c0-5.752 4.664-10.416 10.418-10.416h131.943c5.753 0 10.417 4.664 10.417 10.416v6.945c0 5.752-4.664 10.416-10.417 10.416zm0-83.333h-312.5c-5.752 0-10.417-4.664-10.417-10.417V65.972c0-5.753 4.664-10.417 10.417-10.417h312.5c5.753 0 10.417 4.664 10.417 10.417v34.722c0 5.753-4.664 10.417-10.417 10.417z" />
  </svg>
);

export default NewsPaper;
