import React from 'react';

const Books = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Books icon"
    role="img"
    viewBox="0 0 500 444.445"
  >
    <title>Books</title>
    <desc>icon</desc>
    <path d="M499.228 384.766L400.616 16.545C397.743 5.816 386.814-2.76 373.395.833l-13.412 3.594c-5.364 1.441-10.009 5.555-13.94 12.361-12.179 6.024-45.399 14.939-59.028 15.816-6.805-3.932-12.891-5.173-18.255-3.732l-13.421 3.594c-2.1.564-3.645 1.692-5.338 2.673v-7.361C250 12.439 237.561 0 222.222 0h-55.556c-15.338 0-27.778 12.439-27.778 27.778v55.556H250l88.246 344.565c2.874 10.73 13.803 19.307 27.223 15.713l13.411-3.594c5.365-1.441 10.009-5.557 13.941-12.361 12.074-5.973 45.295-14.93 59.027-15.816 6.806 3.934 12.891 5.174 18.256 3.732l13.42-3.594c13.412-3.594 18.585-16.485 15.704-27.213zm-116.667-6.129L302.639 80.182l53.663-14.375 79.922 298.455-53.663 14.375zM0 333.334h111.111V111.111H0v222.223zM83.333 0H27.778C12.439 0 0 12.439 0 27.778v55.556h111.111V27.778C111.111 12.439 98.672 0 83.333 0zM0 416.666c0 15.34 12.439 27.779 27.778 27.779h55.556c15.338 0 27.778-12.439 27.778-27.779v-55.555H0v55.555zm138.889-83.332H250V111.111H138.889v222.223zm0 83.332c0 15.34 12.439 27.779 27.778 27.779h55.556c15.338 0 27.778-12.439 27.778-27.779v-55.555H138.889v55.555z" />
  </svg>
);

export default Books;
