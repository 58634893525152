import React from 'react';

const User = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="People silhouettes icon"
    role="img"
    viewBox="0 0 500 350"
  >
    <title>Person silhouettes</title>
    <desc>icon</desc>
    <path d="M75 150c27.578 0 50-22.422 50-50s-22.422-50-50-50-50 22.422-50 50 22.422 50 50 50zm350 0c27.578 0 50-22.422 50-50s-22.422-50-50-50-50 22.422-50 50 22.422 50 50 50zm25 25h-50c-13.75 0-26.172 5.547-35.234 14.531 31.484 17.266 53.828 48.438 58.672 85.469H475c13.828 0 25-11.172 25-25v-25c0-27.578-22.422-50-50-50zm-200 0c48.359 0 87.5-39.141 87.5-87.5S298.359 0 250 0s-87.5 39.141-87.5 87.5S201.641 175 250 175zm60 25h-6.484c-16.25 7.812-34.297 12.5-53.516 12.5s-37.188-4.688-53.516-12.5H190c-49.688 0-90 40.312-90 90v22.5c0 20.703 16.797 37.5 37.5 37.5h225c20.703 0 37.5-16.797 37.5-37.5V290c0-49.688-40.312-90-90-90zm-174.766-10.469C126.172 180.547 113.75 175 100 175H50c-27.578 0-50 22.422-50 50v25c0 13.828 11.172 25 25 25h51.484c4.922-37.031 27.266-68.203 58.75-85.469z" />
  </svg>
);

export default User;
