import React from 'react';

const Pdf = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="PDF document icon"
    role="img"
    viewBox="0 0 375 500"
  >
    <title>PDF document</title>
    <desc>icon</desc>
    <path d="M177.637 250.098c-4.883-15.625-4.785-45.801-1.953-45.801 8.203 0 7.421 36.035 1.953 45.801zm-1.66 46.093c-7.52 19.727-16.895 42.285-27.734 61.23 17.871-6.836 38.086-16.797 61.426-21.387-12.403-9.374-24.317-22.85-33.692-39.843zM84.082 418.066c0 .781 12.891-5.273 34.082-39.258-6.543 6.153-28.418 23.926-34.082 39.258zM242.188 156.25H375v320.312A23.381 23.381 0 0 1 351.562 500H23.438A23.381 23.381 0 0 1 0 476.562V23.438A23.381 23.381 0 0 1 23.438 0H218.75v132.812c0 12.891 10.547 23.438 23.438 23.438zm-7.813 167.773c-19.531-11.914-32.52-28.32-41.699-52.539 4.395-18.066 11.328-45.508 6.055-62.695-4.59-28.711-41.406-25.879-46.68-6.641-4.883 17.871-.391 43.066 7.91 75.195-11.328 26.953-28.027 63.086-39.844 83.789-.098 0-.098.098-.195.098-26.465 13.574-71.875 43.457-53.223 66.406 5.469 6.738 15.625 9.766 20.996 9.766 17.48 0 34.863-17.578 59.668-60.352 25.195-8.301 52.832-18.652 77.148-22.656 21.191 11.523 45.996 19.043 62.5 19.043 28.516 0 30.469-31.25 19.238-42.383-13.573-13.281-53.026-9.472-71.874-7.031zm133.789-221.484L272.461 6.836C268.066 2.441 262.109 0 255.859 0H250v125h125v-5.957a23.362 23.362 0 0 0-6.836-16.504zm-72.363 249.316c4.004-2.637-2.441-11.621-41.797-8.789 36.23 15.43 41.797 8.789 41.797 8.789z" />
  </svg>
);

export default Pdf;
