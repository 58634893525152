import React from 'react';

const Images = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Gallery icon"
    role="img"
    viewBox="0 0 500 388.889"
  >
    <title>Gallery</title>
    <desc>icon</desc>
    <path d="M416.667 333.333v13.889c0 23.013-18.655 41.667-41.667 41.667H41.667C18.654 388.889 0 370.234 0 347.222V125c0-23.012 18.654-41.667 41.667-41.667h13.889v180.556c0 38.292 31.153 69.444 69.444 69.444h291.667zM500 263.889V41.667C500 18.654 481.346 0 458.333 0H125c-23.012 0-41.667 18.654-41.667 41.667v222.222c0 23.013 18.654 41.667 41.667 41.667h333.333c23.013 0 41.667-18.655 41.667-41.667zM222.222 83.333c0 23.012-18.654 41.667-41.667 41.667s-41.667-18.655-41.667-41.667 18.655-41.667 41.667-41.667 41.667 18.655 41.667 41.667zm-83.333 125l48.19-48.19c4.067-4.068 10.663-4.068 14.731 0l34.301 34.301L353.746 76.81c4.067-4.067 10.663-4.067 14.731 0l75.967 75.968V250H138.889v-41.667z" />
  </svg>
);

export default Images;
