import React from 'react';

const Chart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Pie chart icon"
    role="img"
    viewBox="0 0 500.002 470.583"
  >
    <title>Pie chart</title>
    <desc>icon</desc>
    <path d="M485.104 264.703H267.006l145.248 145.248c5.552 5.551 14.688 6.002 20.396.625 35.569-33.512 60.036-78.686 67.215-129.467 1.231-8.695-5.984-16.406-14.761-16.406zm-14.55-59.559C462.979 95.346 375.241 7.607 265.443.033c-8.383-.579-15.441 6.489-15.441 14.89v205.662h205.671c8.4-.001 15.46-7.059 14.881-15.441zm-264.67 59.559V46.605c0-8.778-7.711-15.993-16.397-14.761C79.956 47.322-3.767 143.011.131 257.689c4.007 117.776 105.414 214.357 223.244 212.877 46.324-.579 89.127-15.505 124.32-40.469 7.261-5.146 7.738-15.836 1.442-22.132L205.884 264.703z" />
  </svg>
);

export default Chart;
