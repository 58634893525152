import React from 'react';

const Hospital = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Facility icon"
    role="img"
    viewBox="0 0 500 444.445"
  >
    <title>Facility</title>
    <desc>icon</desc>
    <path d="M472.223 83.333H361.111V27.778C361.111 12.413 348.698 0 333.333 0H166.667c-15.364 0-27.778 12.413-27.778 27.778v55.556H27.778C12.413 83.333 0 95.747 0 111.111v319.444c0 7.641 6.25 13.891 13.889 13.891h472.223c7.639 0 13.889-6.25 13.889-13.891V111.111c-.001-15.364-12.414-27.778-27.778-27.778zm-333.334 295.14c0 5.729-4.688 10.416-10.417 10.416H93.75c-5.729 0-10.417-4.688-10.417-10.416V343.75c0-5.729 4.688-10.416 10.417-10.416h34.722c5.729 0 10.417 4.688 10.417 10.416v34.723zm0-111.112c0 5.729-4.688 10.416-10.417 10.416H93.75c-5.729 0-10.417-4.688-10.417-10.416v-34.723c0-5.729 4.688-10.416 10.417-10.416h34.722c5.729 0 10.417 4.688 10.417 10.416v34.723zm138.888 111.112c0 5.729-4.688 10.416-10.416 10.416h-34.723c-5.729 0-10.417-4.688-10.417-10.416V343.75c0-5.729 4.688-10.416 10.417-10.416h34.723c5.729 0 10.416 4.688 10.416 10.416v34.723zm0-111.112c0 5.729-4.688 10.416-10.416 10.416h-34.723c-5.729 0-10.417-4.688-10.417-10.416v-34.723c0-5.729 4.688-10.416 10.417-10.416h34.723c5.729 0 10.416 4.688 10.416 10.416v34.723zm13.889-147.569c0 2.865-2.344 5.208-5.207 5.208h-22.57v22.569c0 2.865-2.344 5.208-5.208 5.208H241.32c-2.864 0-5.208-2.344-5.208-5.208V125h-22.57c-2.864 0-5.208-2.344-5.208-5.208v-17.361c0-2.865 2.344-5.208 5.208-5.208h22.57v-22.57c0-2.865 2.344-5.208 5.208-5.208h17.361c2.864 0 5.208 2.344 5.208 5.208v22.569h22.57c2.863 0 5.207 2.344 5.207 5.208v17.362zm125.001 258.681c0 5.729-4.688 10.416-10.417 10.416h-34.723c-5.729 0-10.416-4.688-10.416-10.416V343.75c0-5.729 4.688-10.416 10.416-10.416h34.723c5.729 0 10.417 4.688 10.417 10.416v34.723zm0-111.112c0 5.729-4.688 10.416-10.417 10.416h-34.723c-5.729 0-10.416-4.688-10.416-10.416v-34.723c0-5.729 4.688-10.416 10.416-10.416h34.723c5.729 0 10.417 4.688 10.417 10.416v34.723z" />
  </svg>
);

export default Hospital;
