import React from 'react';

const Quote = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Quote icon"
    role="img"
    viewBox="0 0 500 437.5"
  >
    <title>Quote</title>
    <desc>icon</desc>
    <path d="M453.125 0h-125c-25.879 0-46.875 20.996-46.875 46.875v125c0 25.879 20.996 46.875 46.875 46.875h78.125v62.5c0 34.473-28.027 62.5-62.5 62.5h-7.812a23.382 23.382 0 0 0-23.438 23.438v46.875a23.382 23.382 0 0 0 23.438 23.438h7.812c86.328 0 156.25-69.922 156.25-156.25V46.875C500 20.996 479.004 0 453.125 0zm-281.25 0h-125C20.996 0 0 20.996 0 46.875v125c0 25.879 20.996 46.875 46.875 46.875H125v62.5c0 34.473-28.027 62.5-62.5 62.5h-7.812a23.382 23.382 0 0 0-23.438 23.438v46.875a23.382 23.382 0 0 0 23.438 23.438H62.5c86.328 0 156.25-69.922 156.25-156.25V46.875C218.75 20.996 197.754 0 171.875 0z" />
  </svg>
);

export default Quote;
